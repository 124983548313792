import React from 'react';

import { Transition } from '@headlessui/react';

import MenuItems from './MenuItems';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import SocialLinks from './SocialLinks';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MobileMenu: React.FC<{
  onClose: () => void;
  show: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const handleClose = () => {
    window.scrollTo(0, 0);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 overflow-hidden z-50 bg-light text-black dark:bg-black dark:text-white`}
      >
        <div className="absolute inset-0 overflow-hidden overflow-y-auto z-50">
          <Transition
            appear={true}
            show={props.show}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {(ref) => (
              <div
                ref={ref}
                className="absolute inset-0 bg-opacity-75 transition-opacity h-full"
              ></div>
            )}
          </Transition>
          <section className="absolute inset-y-0 left-0 pr-10 max-w-full flex sm:pr-16">
            <Transition
              appear={true}
              show={props.show}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              {(ref) => (
                <div ref={ref} className="w-screen z-50 ">
                  <div className="h-full w-full flex flex-col shadow-xl z-80">
                    <div className="w-screen p-4 flex flex-col justify-center items-center z-50 min-h-full overflow-hidden overflow-y-auto">
                      <div className={'flex justify-between mt-10'}>
                        <div className={'text-2xl px-1 mx-1 mb-6 font-bold'}>
                          {t('MobileMenu.heading', 'MENU')}
                        </div>
                        <div className="absolute right-4">
                          <div
                            className="cursor-pointer text-2xl mt-1"
                            color={'white'}
                            title={t('MobileMenu.button.close', 'Close')}
                            onClick={handleClose}
                          >
                            <AiOutlineCloseSquare />
                          </div>
                        </div>
                      </div>

                      <MenuItems onClick={handleClose} mobile />
                      <div className="flex flex-row mt-6">
                        <SocialLinks />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </section>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
