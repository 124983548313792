import React from 'react';
import { useI18next, useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { Listbox } from '@headlessui/react';
import { IoMdArrowDropdown } from 'react-icons/io';
import US from '../images/icons/flags/US.svg';
import CN from '../images/icons/flags/CN.svg';
import ES from '../images/icons/flags/ES.svg';
import RU from '../images/icons/flags/RU.svg';
import VN from '../images/icons/flags/VN.svg';
import JP from '../images/icons/flags/JP.svg';
import TR from '../images/icons/flags/TR.svg';
import PT from '../images/icons/flags/PT.svg';
import ID from '../images/icons/flags/ID.svg';

const langs = {
  en: { name: 'English', flag: US },
  es: { name: '\u0045\u0073\u0070\u0061\u00f1\u006f\u006c', flag: ES },
  pt: { name: '\u0050\u006f\u0072\u0074\u0075\u0067\u0075\u00ea\u0073', flag: PT },
  tr: { name: '\u0054\u00fc\u0072\u006b\u00e7\u0065', flag: TR },
  ru: { name: '\u0440\u0443\u0441\u0441\u043a\u0438\u0439', flag: RU },
  ja: { name: '\u65e5\u672c', flag: JP },
  vi: { name: '\u0054\u0069\u1ebf\u006e\u0067\u0020\u0056\u0069\u1ec7\u0074', flag: VN },
  id: { name: '\u0042\u0061\u0068\u0061\u0073\u0061', flag: ID },
  zh: { name: '\u666E\u901A\u8BDD', flag: CN },
};

const LanguageDropdown: React.FC<{}> = (props) => {
  const { languages, originalPath } = useI18next();
  const { i18n } = useTranslation();

  const currentLangInfo = langs[i18n.language as keyof typeof langs];

  return (
    <div className="block border-2 rounded-lg mt-2 bg-black">
      <Listbox
        value={i18n.language}
        onChange={(local) => {
          i18n.changeLanguage(local);
        }}
      >
        <Listbox.Button className="flex cursor-pointer justify-between inline-flex items-center py-1 px-3 w-38">
          <img src={currentLangInfo.flag} className="mr-2" />
          {currentLangInfo.name}
          <IoMdArrowDropdown className="ml-2" />
        </Listbox.Button>
        <Listbox.Options className="flex flex-col absolute py-2 px-3 ml-8 border-2 border-white rounded-lg bg-cmAqua-7">
          {languages.map((lng) => (
            <Listbox.Option
              key={lng}
              value={lng}
              className="px-4 py-1 inline-flex items-center cursor-pointer"
            >
              <Link to={originalPath} language={lng}>
                <img src={langs[lng as keyof typeof langs].flag} className="mr-2 inline" />{' '}
                {' ' + langs[lng as keyof typeof langs].name}
              </Link>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default LanguageDropdown;
