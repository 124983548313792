import React, { useState } from 'react';

// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';

import Menu from '../../components/Menu';
import MobileMenu from '../../components/MobileMenu';
import LanguageDropdown from '../../components/LanguageDropdown';
import ThemeSwitch from '../../components/ThemeSwitch';
import { FiMenu } from 'react-icons/fi';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Header: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [showMobileMenuRender, setShowMobileMenuRender] = useState<boolean>(false);
  const detectMobile = useMobileDetect();

  return (
    <>
      <div className="hidden md:block">
        <Menu />
      </div>

      {showMobileMenuRender ? (
        <MobileMenu
          show={showMobileMenu}
          onClose={() => {
            setShowMobileMenu(false);
            setTimeout(() => {
              setShowMobileMenuRender(false);
            }, 500);
          }}
        />
      ) : null}

      <div
        className={
          'fixed block w-full md:hidden cursor-pointer p-3 flex justify-between items-center z-50 bg-transparent text-white'
        }
      >
        {/* <FiMenu
          title={t('Header.button.menu', 'Menu')}
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
            setShowMobileMenuRender(!showMobileMenuRender);
          }}
        />
        <div className="flex">
          <ThemeSwitch />
        </div> */}

        <div className="flex">
          <LanguageDropdown />
        </div>
      </div>
    </>
  );
};

export default Header;
