import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MenuItems: React.FC<{ onClick?: () => void; mobile?: boolean }> = (props) => {
  const { t } = useTranslation();
  const scrollDuration = 500;

  return (
    <>
      <Link
        className="my-4 mx-3 transition duration-300 ease-in-out font-bold cursor-pointer"
        activeClass="border-yeti-2 border-b-2"
        to="home"
        spy={true}
        smooth={true}
        duration={scrollDuration}
        onClick={props.onClick}
      >
        {t('MenuItems.home', 'Home')}
      </Link>
      <Link
        className="my-4 mx-3 transition duration-300 ease-in-out font-bold cursor-pointer"
        activeClass="border-yeti-2 border-b-2"
        to="about"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={scrollDuration}
        onClick={props.onClick}
      >
        {t('MenuItems.about', 'About')}
      </Link>
      <Link
        className="my-4 mx-3 transition duration-300 ease-in-out font-bold cursor-pointer"
        activeClass="border-yeti-2 border-b-2"
        to="team"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={scrollDuration}
        onClick={props.onClick}
      >
        {t('MenuItems.team', 'Team')}
      </Link>
      <Link
        className="my-4 mx-3 transition duration-300 ease-in-out font-bold cursor-pointer"
        activeClass="border-yeti-2 border-b-2"
        to="roadmap"
        spy={true}
        hashSpy={true}
        smooth={true}
        duration={scrollDuration}
        onClick={props.onClick}
      >
        {t('MenuItems.roadmap', 'Roadmap')}
      </Link>
    </>
  );
};

export default MenuItems;
