import React from 'react';
import MenuItems from './MenuItems';

import monster_loader from '../images/monster_loader.svg';
import logo from '../images/logo.svg';
import SocialLinks from './SocialLinks';
import { Link } from 'react-scroll';
import LanguageDropdown from './LanguageDropdown';
import ThemeSwitch from './ThemeSwitch';
import discordIcon from '../images/discord-icon.svg';

const Menu: React.FC<{}> = (props) => {
  return (
    <header className="fixed flex flex-row w-full justify-between px-8 sm:px-6 py-1 z-40 h-20 text-white bg-transparent">
      {/* <Link to="home" spy={true} smooth={true}>
        <img src={logo} alt="monsta.party" className="h-16 cursor-pointer" />
      </Link>
      <div className="flex justify-end items-center gap-4 text-lg font-thin">
        <MenuItems />
        <ThemeSwitch />
        <div className="flex gap-2">
          <SocialLinks />
        </div> */}

      <div className="block">
        <LanguageDropdown />
      </div>

      {/* </div> */}
    </header>
  );
};

export default Menu;
